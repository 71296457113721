<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('billing.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-row>
      <v-col cols="6">
        {{ $t('billing.invoiceNumber') }}
      </v-col>
      <v-col cols="6">
        {{ detail.invoice_number }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('billing.paymentGoal') }}
      </v-col>
      <v-col cols="6">
        {{ detail.title }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('billing.amount') }}
      </v-col>
      <v-col cols="6">
        {{ detail.amount_str }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('billing.status') }}
      </v-col>
      <v-col cols="6">
        {{ detail.action_message }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('billing.paidAt') }}
      </v-col>
      <v-col cols="6">
        {{ detail.paid_at ? format(new Date(detail.paid_at), 'dd MMMM yyyy HH:mm') : '' }}
      </v-col>
    </v-row>
    <v-row
      class="justify-center mt-6 mb-4"
      v-if="permissions.includes('EDIT') && !detail.paid_at && !detail.is_active"
    >
      <v-btn type="button" color="success" @click="editBilling">
        {{ $t('billing.btn.edit') }}
      </v-btn>
    </v-row>
    <v-row
      class="justify-center mt-6 mb-4"
      v-if="
        permissions.includes('EDIT') &&
        detail.payment_proof &&
        detail.payment_proof.status !== 'EMPTY'
      "
    >
      <v-btn type="button" color="success" @click="reviewPayment">
        {{ $t('billing.btn.review') }}
      </v-btn>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    async editBilling() {
      this.$store.commit('billing/form/SET_FORM_TYPE', 'EDIT');
      this.$modal.show('modal-billing', {
        billing: this.detail,
        fromBillingDetail: true,
        dispatchAfterString: 'billing/getDetailInitData',
      });
    },
    async reviewPayment() {
      this.$modal.show('modal-billing-payment', {
        paymentProof: this.detail.payment_proof,
      });
    },
  },
};
</script>
